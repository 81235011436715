import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { binanceLogo, trailcoin } from '../images';
import Particles from 'react-particles';
import { CopyToClipboard } from 'react-copy-to-clipboard'; 
import { FaEthereum } from 'react-icons/fa';
import Countdown from 'react-countdown';
import { toast } from 'react-toastify';
import { ConnectWallet, useAddress,  Web3Button } from '@thirdweb-dev/react';
import AirdropCard from './AirdropCard';
import TrailcoinPriceComponent from './PriceFeedV1';
import PriceFeedV1 from './PriceFeedV1';
import ExchangeComponents from './ExchangeComponents';
import BuyCard from './BuyCard';

interface Contribution {
  ethValue: number;
  tokensReceived: number;
}

interface LeaderboardEntry {
  user: string;
  contribution: number;
}

const HeroSection = () => {
  const [trailPrice, setTrailPrice] = useState(0);
  const [copied, setCopied] = useState(false);
  const contractAddress = '0xF1075300d3e57e069EB7ce2E9802Cba99a1c86AD'; // Replace with actual contract address
  
  const [participantCount, setParticipantCount] = useState(0);
  const [waitlist, setWaitlist] = useState(false);

  const handleParticipateClick = () => {
    if (participantCount < 1000) { // Example limit for participants
      setParticipantCount(participantCount + 1);
    } else {
      setWaitlist(true);
    }
  };
  useEffect(() => {
    // Fetch actual price from your API (replace placeholder)
    fetch('YOUR_API_ENDPOINT_FOR_TRAIL_COIN_PRICE')
      .then(res => res.json())
      .then(data => setTrailPrice(data.price));

    // Simulate price changes (remove in production)
    const interval = setInterval(() => {
      setTrailPrice(prev => prev + (Math.random() - 0.5)); // Fluctuate price
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const idoEndDate = new Date('2024-08-20T12:00:00Z').getTime();

  const tokenomics = {
    price: 0.005,
    totalSupply: 100000000,
  };

  const [tokensSold, setTokensSold] = useState(0);
  const [contributionAmount, setContributionAmount] = useState(0);
  const [contributionHistory, setContributionHistory] = useState<Contribution[]>([]);
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);

  const address = useAddress();

  const [countdownFinished, setCountdownFinished] = useState(false);
  const [walletConnectionError, setWalletConnectionError] = useState(null);

  useEffect(() => {
    if (new Date() >= new Date(idoEndDate)) {
      setCountdownFinished(true);
    }
  }, [idoEndDate]);

  const handleContribute = async () => {
    const ethValue = contributionAmount;
    const tokensReceived = ethValue / tokenomics.price;

    // Update tokens sold and contribution history
    setTokensSold(tokensSold + tokensReceived);
    setContributionHistory(prev => [...prev, { ethValue, tokensReceived }]);

    // Update leaderboard (simplified example)
    setLeaderboard(prev => {
      const newEntry = { user: `User ${prev.length + 1}`, contribution: ethValue };
      return [...prev, newEntry].sort((a, b) => b.contribution - a.contribution);
    });

    toast.success(`Contributed ${ethValue} ETH!`, {
      position: "top-center",
      autoClose: 3000,
    });
    setContributionAmount(0);
  };
  

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <span className="text-red-500">Airdrop Ended</span>;
    } else {
      return (
        <div className="flex item-center justify-center space-x-4 text-center">
          <div>
            <span className="block text-2xl font-bold">{days}</span>
            <span className="text-sm">Days</span>
          </div>
          <div>
            <span className="block text-2xl font-bold">{hours}</span>
            <span className="text-sm">Hours</span>
          </div>
          <div>
            <span className="block text-2xl font-bold">{minutes}</span>
            <span className="text-sm">Minutes</span>
          </div>
          <div>
            <span className="block text-2xl font-bold">{seconds}</span>
            <span className="text-sm">Seconds</span>
          </div>
        </div>
      );
    }
  };

  return (
    <motion.section
    className="hero relative min-h-screen overflow-hidden flex flex-col" // Added flex and flex-col
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
    >
    
    {/* Background Image */}
    <div 
      className="absolute top-0 left-0 w-full h-full bg-cover bg-center z-0"
      style={{ 
        backgroundImage: `url('https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80')`   
  

      }} 
    /> 

    {/* Dark Overlay */}
    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-1" />

    {/* Particles */}
    <Particles
      params={{
        particles: {
          number: { value: 80 }, // Reduced number for a cleaner look
          size: { value: 3 },     // Slightly larger particles
          color: { value: "#ffffff" },
          move: { enable: true, speed: 0.5, direction: "none", out_mode: "out" }, // Slower speed
        },
      }}
      className="z-2" // Ensure particles are above the overlay
    />
      <div className="container mx-auto py-20 md:py-32 px-4 md:px-8 lg:px-16 text-center md:text-left relative z-10 "> {/* Added flex-grow */}
  <div className="md:flex md:items-center mt-4">
    <motion.div 
            className="md:w-1/2"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            <motion.h1 
              className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4 text-white tracking-tight"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.5, type: "spring", stiffness: 100 }}
            >
              TRAIL <br />  Unite. Build. Thrive.
            </motion.h1>
            <div className="mt-8 md:mt-0 text-center md:text-left">
              <motion.h3
                className="text-xl md:text-2xl font-semibold text-white mb-2"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.3, duration: 0.8 }}
              >
              </motion.h3>
            </div>
                <motion.p 
              className="text-lg md:text-xl mb-4 text-gray-200 leading-relaxed"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.8, duration: 0.8 }}
            >
           Decentralize your life, grow your wealth, and make a positive impact. Empower yourself in the Web3 revolution.           
            </motion.p>
            <ExchangeComponents/>
             <div className=''><PriceFeedV1/></div>
            <h2 className="text-lg md:text-xl font-semibold text-gray-200 mb-2">
              Contract Address
            </h2>
            <p className="text-sm md:text-base text-purple-400">
              {contractAddress}
            </p>
            <div className="gap-4">
            <button className="mt-4 bg-gradient-to-r from-pink-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md shadow-md hover:opacity-90 transition-opacity Actionbutton2">
               Start Earning Trails
              </button>
             </div>
          </motion.div>
          <BuyCard/>
        </div>
      </div>
    </motion.section>
  )
}

export default HeroSection;

